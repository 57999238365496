import { ErpTableOptions } from '@/types/type';
import { ref } from 'vue';
export default () => {
  const tableRef = ref();

  const columnList: ErpTableOptions[] = [
    {
      label: '销售合同号',
      prop: 'businessContractNo', // 属性
      minWidth: 143,
      value: '',
      type: 'input',
    },
    {
      label: '签约主体',
      prop: 'principalFullName',
      minWidth: 183,
      value: '',
      type: 'input',
    },
    {
      label: '订单创建时间',
      prop: 'createdTime',
      propDesc: 'createdTime',
      minWidth: 157,
      value: [],
      type: 'time',
      filterProp: ['createdTimeStart', 'createdTimeEnd'],
    },
    {
      label: '订单完结时间',
      prop: 'finishTime',
      propDesc: 'finishTime',
      minWidth: 160,
      value: [],
      type: 'time',
      filterProp: ['finishTimeStart', 'finishTimeEnd'],
    },

    {
      label: '订单状态',
      prop: 'status',
      propDesc: 'statusName',
      minWidth: 125,
      value: [],
      type: 'multiSelect',
      options: 'order_status',
    },
    {
      label: '提货状态', // todolist
      prop: 'pickStatus',
      propDesc: 'pickStatusDesc',
      minWidth: 125,
      value: [],
      type: 'multiSelect',
      options: 'pick_status',
    },
    {
      label: '客户是否结清', // todolist
      prop: 'settleStatus',
      propDesc: 'settleStatusDesc',
      minWidth: 158,
      value: '',
      type: 'signSelect',
      options: 'settle_progress_type_status',
    },
    {
      label: '客户',
      prop: 'customerName',
      minWidth: 183,
      value: '',
      type: 'input',
    },
    {
      label: '大类',
      prop: 'category',
      propDesc: 'categoryDesc',
      width: 180,
      type: 'signSelect',
      value: '',
      options: 'goods_category_slim',
    },
    {
      label: '应收总额',
      prop: 'totalArAmount',
      propDesc: 'totalArAmountDesc',
      minWidth: 125,
      value: [],
      type: 'number',
      filterProp: ['totalArAmountMin', 'totalArAmountMax'],
    },
    {
      label: '实收总额',
      minWidth: 125,
      prop: 'paidInAmount',
      propDesc: 'paidInAmountDesc',
      value: [],
      type: 'number',
      filterProp: ['paidInAmountMin', 'paidInAmountMax'],
    },
    {
      label: '已开货物发票金额(不含税)',
      prop: 'outputGoodsInvoiceAmount',
      propDesc: 'outputGoodsInvoiceAmountDesc',
      minWidth: 245,
      type: 'number',
      value: [],
      filterProp: ['outputGoodsInvoiceAmountMin', 'outputGoodsInvoiceAmountMax'],
    },
    {
      label: '已开货物发票税额',
      prop: 'outputGoodsInvoiceTaxAmount',
      propDesc: 'outputGoodsInvoiceTaxAmountDesc',
      minWidth: 183,
      type: 'number',
      value: [],
      filterProp: ['outputGoodsInvoiceTaxAmountMin', 'outputGoodsInvoiceTaxAmountMax'],
    },
    {
      label: '货物发票不开票',
      prop: 'goodsNoTotalAmount',
      propDesc: 'goodsNoTotalAmountDesc',
      minWidth: 183,
      type: 'number',
      value: [],
      filterProp: ['goodsNoTotalAmountMin', 'goodsNoTotalAmountMax'],
    },
    {
      label: '已开服务发票金额(不含税)',
      prop: 'outputServiceInvoiceAmount',
      propDesc: 'outputServiceInvoiceAmountDesc',
      minWidth: 245,
      type: 'number',
      value: [],
      filterProp: ['outputServiceInvoiceAmountMin', 'outputServiceInvoiceAmountMax'],
    },
    {
      label: '已开服务发票税额',
      prop: 'outputServiceInvoiceTaxAmount',
      propDesc: 'outputServiceInvoiceTaxAmountDesc',
      minWidth: 183,
      type: 'number',
      value: [],
      filterProp: ['outputServiceInvoiceTaxAmountMin', 'outputServiceInvoiceTaxAmountMax'],
    },
    {
      label: '服务发票不开票',
      prop: 'serviceNoTotalAmount',
      propDesc: 'serviceNoTotalAmountDesc',
      minWidth: 183,
      type: 'number',
      value: [],
      filterProp: ['serviceNoTotalAmountMin', 'serviceNoTotalAmountMax'],
    },
    {
      label: '应付总额',
      prop: 'totalApAmount',
      propDesc: 'totalApAmountDesc',
      minWidth: 125,
      type: 'number',
      value: [],
      filterProp: ['totalApAmountMin', 'totalApAmountMax'],
    },
    {
      label: '实付总额',
      prop: 'paidOutAmount',
      propDesc: 'paidOutAmountDesc',
      minWidth: 125,
      value: [],
      type: 'number',
      filterProp: ['paidOutAmountMin', 'paidOutAmountMax'],
    },
    {
      label: '已来货物发票金额(不含税)',
      prop: 'inputGoodsInvoiceAmount',
      propDesc: 'inputGoodsInvoiceAmountDesc',
      minWidth: 245,
      value: [],
      type: 'number',
      filterProp: ['inputGoodsInvoiceAmountMin', 'inputGoodsInvoiceAmountMax'],
    },
    {
      label: '已来货物发票税额',
      prop: 'inputGoodsInvoiceTaxAmount',
      propDesc: 'inputGoodsInvoiceTaxAmountDesc',
      minWidth: 183,
      value: [],
      type: 'number',
      filterProp: ['inputGoodsInvoiceTaxAmountMin', 'inputGoodsInvoiceTaxAmountMax'],
    },
    {
      label: '已来服务发票金额(不含税)',
      prop: 'inputServiceInvoiceAmount',
      propDesc: 'inputServiceInvoiceAmountDesc',
      minWidth: 245,
      value: [],
      type: 'number',
      filterProp: ['inputServiceInvoiceAmountMin', 'inputServiceInvoiceAmountMax'],
    },
    {
      label: '已来服务发票税额',
      prop: 'inputServiceInvoiceTaxAmount',
      propDesc: 'inputServiceInvoiceTaxAmountDesc',
      minWidth: 183,
      value: [],
      type: 'number',
      filterProp: ['inputServiceInvoiceTaxAmountMin', 'inputServiceInvoiceTaxAmountMax'],
    },
    {
      label: '本票利润(含税)',
      prop: 'ownerProfit',
      propDesc: 'ownerProfitDesc',
      minWidth: 174,
      value: [],
      type: 'number',
      filterProp: ['ownerProfitMin', 'ownerProfitMax'],
    },
    {
      label: '订单毛利(不含税)',
      prop: 'orderProfit',
      propDesc: 'orderProfitDesc',
      minWidth: 187,
      value: [],
      type: 'number',
      filterProp: ['orderProfitMin', 'orderProfitMax'],
    },
  ];
  return { columnList, tableRef };
};

import { createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ErpList = _resolveComponent("ErpList")!

  return (_openBlock(), _createBlock(_component_ErpList, {
    url: "/malicrm/profit/queryOrderProfitListWithPage",
    columOptions: _ctx.columnList,
    ref: "tableRef",
    id: "profitOrder",
    onRowClick: _ctx.rowClick
  }, {
    header: _withCtx(() => [
      _createElementVNode("button", {
        class: "erp-button",
        onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.exportFileFn()))
      }, "导出")
    ]),
    _: 1
  }, 8, ["columOptions", "onRowClick"]))
}